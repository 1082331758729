function Hero({ salutation, name, description, subtitle }) {
	return (
		<section className="hero is-link is-fullheight is-fullheight-with-navbar diagonal-hero-bg">
			<div className="hero-body starss">
				<div className="small" />
				<div className="medium" />
				<div className="big" />
				<div className="container css-typing">
					{salutation && <p className="is-5">{salutation}</p>}
					{name && <h1 className="title is-1"> {name} </h1>}
					{description && <h2 className="subtitle is-6">{description}</h2>}
					{subtitle && <p className="subtitle is-4">{subtitle}</p>}
				</div>
			</div>
		</section>
	);
}

export default Hero;
