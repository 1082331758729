import SectionTitle from './home/sectionTitle';

function About({ children, id }) {
	return (
		<section className="section" id={id}>
			<div className="container">
				<div className="section section-head has-text-centered">
					<SectionTitle title="About me" subtitle="“ Talk is cheap. Show me the code ”" />
					<div className="columns is-mobile is-centered is-size-5">
						<div className="container">
							<p>
								Full Stack Software Engineer, with well-rounded years of experience
								across the stack. Specialized knowledge of modern Web techniques and
								love for <strong> Coffee </strong>.
							</p>
							<p>I'll work together with you to realise your digital ambitions.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default About;
