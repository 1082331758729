import Fade from 'react-reveal/Fade';
import React, { Fragment } from 'react';
import { motion } from 'framer-motion';
import { NextSeo } from 'next-seo';
import Hero from '../components/hero';
import About from '../components/about';
import Services from '../components/services';
import Works from '../components/works';
import Contact from '../components/contact';

const postVariants = {
	initial: { scale: 1, y: 0, opacity: 0 },
	enter: {
		scale: 1,
		y: 0,
		opacity: 1,
		transition: { duration: 0.5, ease: [0.48, 0.15, 0.25, 0.96] }
	},
	exit: {
		scale: 0.6,
		y: 100,
		opacity: 0,
		transition: { duration: 0.2, ease: [0.48, 0.15, 0.25, 0.96] }
	}
};

function indexPage({ children }) {
	return (
		<>
			<NextSeo title="ALMESSADI" titleTemplate="%s | Alaeddine Messadi" />

			<motion.div
				initial="initial"
				animate="enter"
				exit="exit"
				variants={{ exit: { transition: { staggerChildren: 0.1 } } }}>
				<motion.div variants={postVariants}>
					<Hero
						salutation="Hi! My name is"
						name="Alaeddine Messadi"
						description="and I'm a Full Stack Software Engineer"
						subtitle="Based in Berlin <3"
					/>
					<About id="about" />

					<Services id="services" />
					<Works id="works" />

					<Fade opposite>
						<Contact id="contact" />
					</Fade>
				</motion.div>
			</motion.div>
		</>
	);
}

export default indexPage;
