import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MessageComponent from '../admin/message';
import InputField from '../home/inputField';
import { post } from '../../utils/apiClient';

function ContactForm({ children }) {
	const [response, setResponse] = useState('');
	const [isSending, setIsSending] = useState(false);

	return (
		<section>
			<Formik
				initialValues={{ email: '', name: '', body: '' }}
				onSubmit={async (values, { resetForm }) => {
					setIsSending(true);
					try {
						const result = await post('/mailer', { data: values });
						// resetForm({ values: '' });
						setIsSending(false);
						setResponse({
							title: 'Success',
							body: result.data.message,
							type: 'success'
						});
					} catch (error) {
						setIsSending(false);
						setResponse({
							title: 'Error',
							body: 'Oups! Something went wrong.. please try later',
							type: 'danger'
						});
					}
				}}
				validationSchema={Yup.object().shape({
					email: Yup.string()
						.email()
						.required('Please Enter your Email'),
					name: Yup.string().required('Please enter your name'),
					body: Yup.string()
						.required('Please write your message')
						.min(40, 'You message should be at least 20 letters')
				})}>
				{props => {
					const {
						values,
						touched,
						errors,
						dirty,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						handleReset
					} = props;
					return (
						<form onSubmit={handleSubmit}>
							<InputField
								name="name"
								id="name"
								type="input"
								placeholder="Your Name"
								icon="fas fa-user"
								classes="is-medium"
								error={errors.name && touched.name ? errors.name : ''}
								classes={errors.name && touched.name ? 'is-danger' : ''}
								value={values.name}
								onChange={handleChange}
								onBlur={handleBlur}
							/>

							<InputField
								name="email"
								type="email"
								placeholder="Your Email"
								icon="fas fa-envelope"
								classes="is-medium"
								error={errors.email && touched.email ? errors.email : ''}
								classes={errors.email && touched.email ? 'is-danger' : ''}
								value={values.email}
								onChange={handleChange}
								onBlur={handleBlur}
							/>

							<InputField
								name="body"
								id="body"
								label="Message"
								type="textarea"
								placeholder="Your Message"
								classes="is-medium"
								error={errors.body && touched.body ? errors.body : ''}
								classes={errors.body && touched.body ? 'is-danger' : ''}
								value={values.body}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							{/* is-dark is-fullwidth has-text-weight-medium is-medium */}
							<div className="control">
								{!response ? (
									<button
										type="submit"
										className="button is-dark is-fullwidth has-text-weight-medium is-medium"
										disabled={isSending}>
										{' '}
										Send Message &nbsp;
										{isSending && <i className="fa fa-spinner fa-spin" />}
									</button>
								) : (
									<MessageComponent
										handleClose={e => setResponse('')}
										{...response}
									/>
								)}
							</div>
						</form>
					);
				}}
			</Formik>
		</section>
	);
}

export default ContactForm;
