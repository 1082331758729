import SectionTitle from './home/sectionTitle';
import ContactForm from './form/contactForm';

function Contact({ children, id }) {
	return (
		<section className="section" id={id}>
			<div className="container">
				<div
					className="columns is-multiline"
					data-aos="fade-in-up"
					data-aos-easing="linear">
					<div className="column is-12 has-text-centered">
						<SectionTitle
							title="CONTACT ME"
							subtitle="How can technology help you to realise your ambitions? Get in touch and let's find out."
						/>
					</div>
					<div className="column is-8 is-offset-2">
						<ContactForm />
					</div>
				</div>
			</div>
		</section>
	);
}

export default Contact;
