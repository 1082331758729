import { motion } from 'framer-motion';
import { useState } from 'react';
import WorkCard from './workCard';

function WorkExample({ company, year, services, description, image, link, status, tech = [] }) {
	const [isOn, setIsOn] = useState(false);

	// return (<WorkCard />)

	return (
		<div className="card card-work">
			<div className="card-content no-padding" style={{ overflow: 'hidden' }}>
				<motion.div
					onHoverStart={e => setIsOn(true)}
					onTap={e => setIsOn(!isOn)}
					onHoverEnd={e => setIsOn(false)}
					whileHover="hover"
					whileTap="hover"
					variants={{ hover: { scale: 1.1 } }}>
					<figure className="image is-1by1">
						<img src={image} alt={company} />
					</figure>

					<ul className="social-icons">
						{tech.map((e, i) => (
							<li key={i} data-tooltip={e === 'js' ? 'Javascript' : e}>
								<span>
									<i className={`fab fa-${e}`} />
								</span>
							</li>
						))}
					</ul>
				</motion.div>
			</div>
			<footer className="jane">
				<motion.p
					className="is-size-5 has-text-dark"
					animate={isOn ? 'on' : 'off'}
					variants={{
						off: { scale: 1 },
						on: { scale: 1.1 }
					}}>
					<a href={link} target="_blank" className="has-text-dark">
						{company}
					</a>
				</motion.p>
				<p>{description}</p>
				<p className="jane-footer-item">
					<a href={link} target="_blank">
						<i className="fas fa-link" />
					</a>
				</p>
			</footer>
		</div>
	);
}

export default WorkExample;
