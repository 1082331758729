import Fade from 'react-reveal/Fade';
import SectionTitle from './home/sectionTitle';
import WorkExample from './home/workExample';

function Works({ children, id }) {
	const worksJson = [
		{
			company: 'Customer Allience GmbH',
			image: require('../assets/img/capic.jpg'),
			year: 2016,
			Services: 'Symfony, Java, CSS3, Bootstrap, HTML5, jQuery, WordPress',
			link: 'https://www.customer-alliance.com',
			description: 'SAAS - Hotel Review Platform',
			tech: ['symfony', 'wordpress', 'js', 'bootstrap', 'php']
		},
		{
			company: 'Sparwelt GmbH',
			image: require('../assets/img/sparweltgmbh.jpg'),
			year: 2018,
			Services: 'Symfony, CSS3, Bootstrap, HTML5, jQuery, Vue.js, React.js',
			link: 'https://www.sparwelt.de',
			description: 'Voucher Portal',
			tech: ['symfony', 'css3', 'js', 'vuejs']
		},
		{
			company: 'Focus ONLINE',
			image: require('../assets/img/focus.jpg'),
			year: 2018,
			Services: 'Symfony, CSS3, Bootstrap, HTML5, jQuery, Vue.js, React.js',
			link: 'https://gutscheine.focus.de',
			description: 'Voucher Portal',
			tech: ['js', 'css3', 'vuejs', 'symfony']
		},
		{
			company: 'Legal One GmbH',
			image: require('../assets/img/legalonegmbh.jpg'),
			year: 20119,
			Services: 'Symfony, CSS3, HTML5, Docker, Vue.js, React.js',
			link: 'https://legal.one',
			description: 'Legal tech ',
			tech: ['react', 'css3', 'js', 'docker', 'aws']
		},
		{
			company: 'Smava GmbH',
			image: require('../assets/img/smavagmbh.jpg'),
			year: 2020,
			Services: 'React.js, Node.js, Aws, CSS3, HTML5, UX, UI',
			link: 'https://www.smava.de',
			description: 'Online loan comparison platform.',
			tech: ['react', 'aws', 'css3', 'node']
		},
		{
			company: 'Stats-Coronavirus',
			image: require('../assets/img/stats-coronavirus.com.jpg'),
			year: 2020,
			Services: 'React.js, Next.js, Node.js, Bulma, API ',
			link: 'https://stats-coronavirus.com',
			description: 'Realtime dashboard & Api for COVID-19',
			tech: ['react', 'node', 'css3', 'js', 'html5']
		}
	];
	return (
		<section className="section" id={id}>
			<div className="section section-head has-text-centered">
				<div className="container">
					<SectionTitle title="Work" />
					<div className="section container">
						<div className="columns is-multiline">
							{worksJson.map((args, i) => (
								<div key={i} className="column is-4">
									<Fade opposite>
										<WorkExample {...args} />
									</Fade>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Works;
