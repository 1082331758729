import Fade from 'react-reveal/Fade';
import SectionTile from './home/serviceTIle';
import { svg } from '../utils/constants';

function Services({ children, id }) {
	const servicesTiles = [
		{
			title: 'Strategy',
			icon: svg.strategy,
			description:
				'I develop Web applications for interactive, spicy solutions and transform great ideas into usable Application for beautiful products'
		},
		{
			title: 'Code',
			icon: svg.code,
			description:
				"Using the latest technologies and it's benefits: faster, smarter, simpler and more robust. Nodejs, React, React-Native, GraphQL, Vue.js, Flutter, AWS..."
		},
		{
			title: 'Performance',
			icon: svg.performance,
			description:
				'Have you visited a website from an old smart phone under a slow network connection? And then, have you visited that website ever again? '
		},
		{
			title: 'UX/UI',
			description:
				'Interfaces focused on content structure, intuitive UI patterns and simple interactions with fully responsive design',
			icon: svg.ui
		},
		{
			title: 'Creativity',
			description:
				'Your ideas will be mixed with proven technologies to achieve your business goals in the best way.',
			icon: svg.creative
		},
		{
			title: 'SEO',
			description:
				'Does Google hate your website? I will figure out why and get you more traffic',
			icon: svg.seo
		}
	];

	// const servicesBigTiles = [
	// 	{
	// 		title: 'Frontend',
	// 		description:
	// 			'For a fully responsive design website, I use mobile first approach, for mobile optimized performance through a full responsive layouts, contents and with best practices in terms of usability.',
	// 		icon: 'fab fa-2x fa-html5',
	// 		bgColor: 'is-info',
	// 	},
	// 	{
	// 		title: 'Backend',
	// 		description:
	// 			'When it comes to Backend I aim to design an architecture which is maintainable, extensible, robust, scalable, secure with fast processing code Technologies Node.js / PHP (Symfony, Laravel) and SQL / NoSQL databases.',
	// 		icon: 'fas fa-2x fa-server',
	// 		bgColor: 'is-primary',
	// 	},
	// 	{
	// 		title: 'Databases',
	// 		description:
	// 			'Working especially with large-scale data, even the most minor change can have a dramatic impact on the performance. I create an appropriate structure that cover the basics of building and sustaining preferment database',
	// 		icon: 'fas fa-2x fa-database',
	// 		bgColor: 'is-warning',
	// 	},
	// ]

	return (
		<section className="" id={id}>
			{/* <SectionTitle title="Services" /> */}
			<div className="section container">
				<div className="columns is-multiline">
					{servicesTiles.map((tileProps, i) => (
						<div key={i} className="column is-one-third">
							<Fade opposite>
								<SectionTile {...tileProps} />
							</Fade>
						</div>
					))}
				</div>
			</div>

			{/* <div className="section">
				<div className="container">
					<div className="columns">
						{ servicesBigTiles.map((tilesProps, i) => (
							<div key={ i } className="column">
								<Fade opposite>
									<ServiceBigTile { ...tilesProps } />
								</Fade>
							</div>
						)) }
					</div>
				</div>
			</div> */}
		</section>
	);
}

export default Services;
