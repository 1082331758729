function SectionTile({ title, description, icon }) {
	return (
		<div className="card has-text-centered is-shadowless">
			<div className="card-image center">
				<figure className="image" style={{ width: '100px' }}>
					{icon}
				</figure>
			</div>
			<div className="card-content">
				<p className="title is-4">{title}</p>
				<div className="content">{description}</div>
			</div>
		</div>
	);
}

export default SectionTile;
